import { NextSeo } from 'next-seo';
import React from 'react';
import SignInPage from '../components/tailwind/sign-in/sign-in-page.component';
import { CLIENT_APP_URL } from '../constants/app.constants';
import { useRouter } from 'next/router';

const SignIn = () => {
  const router = useRouter()

  if (typeof window !== 'undefined' && !window.location.origin.includes(process.env.NEXT_PUBLIC_PRO_WEBSITE as string)) {
    router.push(`${process.env.NEXT_PUBLIC_V2_APP_URL}`);
    return null;
  }

  return (
    <>
    <NextSeo
      title="Sign In"
      canonical={`${CLIENT_APP_URL}/sign-in`}
      openGraph={{
        url: `${CLIENT_APP_URL}/sign-in`,
      }}
    />
    <SignInPage />
  </>
  )
}
  

export default SignIn;
